<template>
  <van-tabbar v-model="active">
    <!--
    <van-tabbar-item to="/" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item to="/category" icon="apps-o">分类</van-tabbar-item>
    -->
    <van-tabbar-item :to="'/' + appId+ '/guardian'" icon="friends-o" name="guardian">学员</van-tabbar-item>
    <van-tabbar-item v-if="showEduTab" :to="'/' + appId+ '/edu'" icon="records" name="edu">教务</van-tabbar-item>
    <van-tabbar-item :to="'/' + appId+ '/ucenter'" icon="user-o" name="ucenter">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import guardianApi from '@/api/guardian'
import eduApi from '@/api/edu'
export default {
  data() {
    return {
      appId: '',

      showEduTab: false
    }
  },
  computed: {
    active: {
      // 判断高亮的tabbar icon
      get() {
        switch (this.$route.path) {
          case `/${this.appId}/guardian`:
            return 'guardian'
          case `/${this.appId}/ucenter`:
            return 'ucenter'
          case `/${this.appId}/edu`:
            return 'edu'
          default:
            return 0
        }
      },
      set() {}
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.checkEduTab()
  },
  methods: {
    checkEduTab() {
      eduApi.showTab().then(res => {
        this.showEduTab = res.succ && res.data.item
      })
    }
  }
}
</script>

<style>
</style>
