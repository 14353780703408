import request from '@/utils/request'

const prefix = '/api/guardian'

export default {
  showTab() {
    return request.get(`${prefix}/showTab`)
  },
  studentList( ) {
    return request.get(`${prefix}/student/list` )
  },
  contractList(params) {
    return request.get(`${prefix}/contract/list`, {params})
  },
  contractDetail(id) {
    return request.get(`${prefix}/contract/${id}`)
  },
  classHourList(params) {
    return request.get(`${prefix}/classHour/list`, {params})
  },
  classHourDetail(id) {
    return request.get(`${prefix}/classHour/${id}`)
  },
  classHourLogList(params) {
    return request.get(`${prefix}/classHourLog/list`, {params})
  },
  classHourLogDetail(id) {
    return request.get(`${prefix}/classHourLog/${id}`)
  },
  signInOutList(params) {
    return request.get(`${prefix}/signInOut/list`, {params})
  },
  lessonLogList(params) {
    return request.get(`${prefix}/lessonLog/list`, {params})
  }
}
