import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



// import request from '@/utils/request'

// 使用router的全局守卫进行权限控制
import '@/wxcom-global-guards'

// 批量引入vantui
import '@/vantui'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
// 引入字体样式
import '@/assets/fonts/van-ext-fonts.css'

// 自定义过滤器
import '@/utils/filters'

// 阻止vue在启动时生成生产提示
Vue.config.productionTip = false

// 全局挂载
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx

// 弹幕插件
import { vueBaberrage } from 'vue-baberrage'
Vue.use(vueBaberrage)

// 抽奖转盘
import LuckDraw from 'vue-luck-draw'
Vue.use(LuckDraw)

// 百度统计
var _hmt = _hmt || []
window._hmt = _hmt;
(function() {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?f9df1ded612a0bdb76da7eb231965459'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

const removeCode = (to) => {
  let path = to.path
  for (let i in to.query) {
    if (i !== 'code' && i !== 'state') {
      path = path + '&' + i + '=' + to.query[i]
    }
  }
  // path = path === '' ? path : path.substring(1, path.length)
  // path = path === '' ? path : '/?' + path
  return path
}


new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
