import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    token: null,
    authorizer: {}
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    token(state) {
      return state.token
    },
    authorizer(state) {
      return state.authorizer
    }
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
    },
    setToken(state, data) {
      state.token = data
    },
    removeUserInfo(state) {
      state.userInfo = {}
      state.token = ''
    },
    setAuthorizer(state, data) {
      state.authorizer = data
    }
  },
  actions: {
    loadUseInfo(context, data) {
      context.commit('setUserInfo', data)
    },
    loadToken(context, data) {
      context.commit('setToken', data)
    },
    saveAuthorizer(context, data) {
      context.commit('setAuthorizer', data)
    }
  },
  modules: {
  }
})
