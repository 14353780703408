import Vue from 'vue'

Vue.filter('formatMoney', function(val) {
  let newVal = Number(val)
  return '￥' + newVal.toFixed(2)
})

Vue.filter('formatTeamStatus', function(val) {
  if (val === 0) {
    return '组队中'
  } else if (val === 1) {
    return '组队成功'
  } else{
    return '组队失败'
  }
})

Vue.filter('formatOrderStatus', function(val) {
  if (val === 1) {
    return '已完成'
  }
  if (val === 2) {
    return '支付失败'
  }
  if (val === -1) {
    return '已取消'
  }
  if (val === 0) {
    return '未支付'
  } else {
    return '未知'
  }
})

Vue.filter('formatContractStatus', function(val) {
  if (val === 1) {
    return '执行中'
  } else if (val === 0) {
    return '未开始'
  } else {
    return '已结束'
  }
})

Vue.filter('formatSignType', function(val) {
  if (val === 1) {
    return '签到'
  } else {
    return '签退'
  }
})

Vue.filter('formatWeekday', function(weekday) {
  let arr = ['一', '二', '三', '四', '五', '六', '日']
  return '周' + arr[weekday - 1]
})

/**
 * 时间格式化：将"3:0:0:0"格式化成"03天 00:00:00"
 */
Vue.filter('filterTime', function(value) {
  if (!value) {
    return '00:00:00'
  }
  // value 不是时间格式 时：分： 秒
  if (value.indexOf(':') === -1) {
    return value
  }
  let result = ''
  const dayTimeArr = value.split(':')
  if(parseInt(dayTimeArr[0]) > 0) {
    // “天”位大于0时，才显示
    result =  dayTimeArr[0] + ' 天 '
  }
  // 对小时、分钟、0秒数 补0
  result +=  fillZero(dayTimeArr[1]) + ':' + fillZero(dayTimeArr[2]) + ':' + fillZero(dayTimeArr[3])
  return result
})

function fillZero(val) {
  return parseInt(val) < 10 ? ('0' + val) : val
}
