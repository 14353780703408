import axios from 'axios'

import cache from '@/plugins/cache'

import {tansParams} from './comm'

import store from '@/store'

import {Toast} from 'vant'

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/' : '/',
  // 超时时间
  timeout: 5000
})

service.interceptors.request.use(config => {
  if(store.getters.token) {
    // 设置token
    config.headers['token'] = store.getters.token
  }
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params)
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  console.log('before repeat submit')
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    console.log('in repeat submit')
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url                  // 请求地址
      const s_data = sessionObj.data                // 请求数据
      const s_time = sessionObj.time                // 请求时间
      const interval = 1000                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交'
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.error(error)
  return Promise.reject(error)
})


service.interceptors.response.use(res => {
  if(res.status === 200) {
    // response状态码
    return res.data
  } else {
    return Promise.reject(res)
  }
}, error => {
  let {message} = error
  Toast({
    message: message
  })
  return Promise.reject(error)
})

export default service
