// 用于引入vant组件

import Vue from 'vue'

import { Button, List, Cell, CellGroup, Search, Swipe, Grid, GridItem, Popup, Lazyload, Loading,
  SwipeItem, Icon, Tag, DropdownMenu, DropdownItem, GoodsAction, GoodsActionIcon, GoodsActionButton,
  Empty, Tabbar, TabbarItem, Col, Row, Form, Field, Tab, Tabs, Card, NavBar, Dialog, Skeleton,
  Image as VanImage, Badge, NumberKeyboard, Picker, RadioGroup, Radio, Progress, Calendar, Checkbox,
  CheckboxGroup, DatetimePicker, Stepper, Sticky, TreeSelect  } from 'vant'

Vue.use(TreeSelect)
Vue.use(Sticky)
Vue.use(Stepper)
Vue.use(DatetimePicker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Calendar)
Vue.use(Progress)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Picker)
Vue.use(NumberKeyboard)
Vue.use(Badge)
Vue.use(VanImage)
Vue.use(Skeleton)
Vue.use(Dialog)
Vue.use(NavBar)
Vue.use(Card)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(CellGroup)
Vue.use(Loading)
Vue.use(Lazyload)
Vue.use(Popup)
Vue.use(Button)
Vue.use(List)
Vue.use(Cell)
Vue.use(Search)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Icon)
Vue.use(Tag)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Empty)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Form)
Vue.use(Field)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
