import request from '@/utils/request'

const prefix = '/api/edu'

export default {
  showTab() {
    return request.get(`${prefix}/showTab`)
  },
  clazzList() {
    return request.get(`${prefix}/clazz/list`)
  },
  clazzSchedule(id) {
    return request.get(`${prefix}/clazz/${id}/clazzSchedule`)
  },
  studentList(id) {
    return request.get(`${prefix}/clazz/${id}/students`)
  },
  contractList(params) {
    return request.get(`${prefix}/contract/list`, {params})
  },
  contractDetail(id) {
    return request.get(`${prefix}/contract/${id}`)
  },
  classHourList(params) {
    return request.get(`${prefix}/classHour/list`, {params})
  },
  classHourDetail(id) {
    return request.get(`${prefix}/classHour/${id}`)
  },
  classHourLogList(params) {
    return request.get(`${prefix}/classHourLog/list`, {params})
  },
  classHourLogDetail(id) {
    return request.get(`${prefix}/classHourLog/${id}`)
  },
  signInOutList(params) {
    return request.get(`${prefix}/signInOut/list`, {params})
  },
  lessonLogList(params) {
    return request.get(`${prefix}/lessonLog/list`, {params})
  },
  wxUserList(studentId, msgScene) {
    return request.get(`${prefix}/${studentId}/wxUsers?msgScene=${msgScene}`)
  },
  saveSignInOutLog(data) {
    return request.post(`${prefix}/signInOut/save`, data)
  },
  batchSaveSignInOutLog(data) {
    return request.post(`${prefix}/signInOut/batchSave`, data)
  },
  beforeAddLessonLog(clazzId, studentId) {
    return request.get(`${prefix}/lessonLog/${clazzId}/${studentId}/beforeAdd`)
  },
  saveLessonLog(data) {
    return request.post(`${prefix}/lessonLog/save`, data)
  },
  beforeBatchAddLessonLog(clazzId ) {
    return request.get(`${prefix}/lessonLog/${clazzId}/beforeBatchAdd`)
  },
  batchSaveLessonLog(data) {
    return request.post(`${prefix}/lessonLog/batchSave`, data)
  },
  studentDetail(id) {
    return request.get(`${prefix}/student/${id}`)
  },
  searchStudent(params) {
    return request.get(`${prefix}/searchStudent`, {params})
  },
  studentBoundWxUser(id) {
    return request.put(`${prefix}/student/${id}/boundWxUser`)
  },
  courseNoticeList(params) {
    return request.get(`${prefix}/courseRemind/list`, {params})
  },
  beforeSendCourseNotice(clazzId) {
    return request.get(`${prefix}/courseRemind/beforeSend?clazzId=${clazzId}`)
  },
  sendRemindLessonMsg(data) {
    return request.post(`${prefix}/courseRemind/send`, data)
  }
}
