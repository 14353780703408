import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import request from '@/utils/request'

import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/wxCom/auth',
    name: 'WxComAuth',
    meta: {
      title: '公众号授权'
    },
    component: () => import('@/views/wxcom/Auth.vue')
  },
  {
    path: '/wxPay/callbackTicket',
    name: 'WxPayCallbackTicket',
    meta: {
      title: '商家小票'
    },
    component: () => import('@/views/wxpay/CallbackTicket.vue')
  },
  {
    // 首页跳转到个人中心页面
    path: '/:appId/',
    redirect: '/:appId/ucenter'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'Index',
    meta: {
      showTabbar: true,
      title: '首页'
    },
    component: () => import('@/views/Index.vue'),
    children: [
      {
        path: 'popup',
        name: 'MyPopup',
        component: () => import('@/views/MyPopup.vue')
      }
    ]
  },
  {
    path: '/category',
    name: 'Category',
    meta: {
      showTabbar: true
    },
    component: () => import('@/views/MyCategory.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {
      showTabbar: true
    },
    component: () => import('@/views/MyCart.vue')
  },
  {
    /* 用户中心 */
    path: '/:appId/ucenter',
    name: 'Ucenter',
    meta: {
      showTabbar: true
    },
    component: () => import('@/views/ucenter/Index.vue')
  },
  {
    path: '/:appId/ucenter/profitShare/list',
    name: 'ProfitShareList',
    meta: {
      showTabbar: false
    },
    component: () => import('@/views/ucenter/profit-share/List.vue')
  },
  {
    path: '/:appId/ucenter/groupOn/teamList',
    name: 'MyGroupOnTeamList',
    meta: {
      showTabbar: false
    },
    component: () => import('@/views/ucenter/group-on/TeamList.vue')
  },
  {
    path: '/:appId/ucenter/order/list',
    name: 'MyActivityOrderList',
    meta: {
      showTabbar: false
    },
    component: () => import('@/views/ucenter/order/List.vue')
  },
  {
    path: '/:appId/ucenter/order/detail/:orderNum',
    name: 'MyActivityOrderDetail',
    meta: {
      showTabbar: false
    },
    component: () => import('@/views/ucenter/order/Detail.vue')
  },
  {
    path: '/:appId/product/detail/:id',
    name: 'ProductDetail',
    meta: {
    },
    component: () => import('@/views/ProductDetail.vue')
  },
  {
    /* 用于替换activity,机构id/活动id/推荐人id */
    path: '/:appId/distribute/detail/:id/:refereeId',
    name: 'Distribute',
    meta: {
      title: '活动详情'
    },
    component: () => import('@/views/activity/distribute/Index.vue')
  },
  {
    /* 机构id/活动id/推荐人id */
    path: '/:appId/groupOn/detail/:id/:teamId',
    name: 'GroupOn',
    meta: {
      title: '团购详情'
    },
    component: () => import('@/views/activity/group-on/Detail.vue')
  },
  // {
  //   /* 机构id/活动id/推荐人id */
  //   path: '/:appId/groupOn/create/:id/:teamId',
  //   name: 'GroupOnCreate',
  //   meta: {
  //     title: '我要开团'
  //   },
  //   component: () => import('@/views/activity/group-on/Create.vue')
  // },
  {
    /* 机构id/活动id/推荐人id */
    path: '/:appId/groupOn/join/:id/:teamId',
    name: 'GroupOnJoin',
    meta: {
      title: '参与团购'
    },
    component: () => import('@/views/activity/group-on/Create.vue')
  },
  // {
  //   /* 作废，改用点金计划。机构id/活动id/推荐人id */
  //   path: '/groupOnResult/:appId/:id/:teamId',
  //   name: 'GroupOnResult',
  //   meta: {
  //     title: '结果'
  //   },
  //   component: () => import('@/views/activity/group-on/Result.vue')
  // },
  {
    /* 机构id/活动id/推荐人id */
    path: '/:appId/freeTeam/detail/:id/:teamId',
    name: 'FreeTeam',
    meta: {
      title: '活动详情'
    },
    component: () => import('@/views/activity/free-team/Detail.vue')
  },
  {
    /* 组队活动：格式：机构id/活动id/teamId */
    path: '/:appId/freeTeam/create/:id/:teamId',
    name: 'FreeTeamCreate',
    meta: {
      title: '我来组队'
    },
    component: () => import('@/views/activity/free-team/Create.vue')
  },
  {
    /* 组队活动：格式：机构id/活动id/teamId */
    path: '/:appId/freeTeam/join/:id/:teamId',
    name: 'FreeTeamJoin',
    meta: {
      title: '加入队伍'
    },
    component: () => import('@/views/activity/free-team/Create.vue')
  },
  {
    /* 组队活动: 队伍列表：格式：机构id/活动id */
    path: '/:appId/freeTeam/teamList/:id',
    name: 'FreeTeamList',
    meta: {
      title: '团队列表'
    },
    component: () => import('@/views/activity/free-team/TeamList.vue')
  },
  {
    path: '/:appId/orderConfirm/:orderNum',
    name: 'OrderConfirm',
    component: () => import('@/views/OrderConfirm.vue')
  },
  {
    path: '/:appId/guardian',
    name: 'Guardian',
    meta: {
      title: '学员平台',
      showTabbar: true
    },
    component: () => import('@/views/guardian/Index.vue')
  }, {
    path: '/:appId/guardian/boundStudent',
    name: 'Guardian',
    meta: {
      title: '绑定学员信息',
      showTabbar: true
    },
    component: () => import('@/views/guardian/BoundStudent.vue')
  },
  {
    path: '/:appId/guardian/contract/list',
    name: 'ContractList',
    meta: {
      title: '合同列表'
    },
    component: () => import('@/views/guardian/contract/List.vue')
  },
  {
    path: '/:appId/guardian/contract/detail/:id',
    name: 'ContractDetail',
    meta: {
      title: '合同详情'
    },
    component: () => import('@/views/guardian/contract/Detail.vue')
  },

  {
    path: '/:appId/guardian/classHour/list',
    name: 'ClassHourList',
    meta: {
      title: '课时账户'
    },
    component: () => import('@/views/guardian/class-hour/List.vue')
  },
  {
    path: '/:appId/guardian/classHourLog/list/:classHourId/:studentId',
    name: 'ClassHourLogList',
    meta: {
      title: '课时消耗记录'
    },
    component: () => import('@/views/guardian/class-hour-log/List.vue')
  },
  {

    path: '/:appId/guardian/lessonLog/list',
    name: 'LessonLogList',
    meta: {
      title: '上课记录'
    },
    component: () => import('@/views/guardian/lesson-log/Index.vue')
  },
  {
    path: '/:appId/guardian/signInOut/list',
    name: 'SignInOutList',
    meta: {
      title: '签到/签退记录'
    },
    component: () => import('@/views/guardian/sign-in-out/Index.vue')
  },
  {
    path: '/:appId/edu',
    name: 'EduIndex',
    meta: {
      title: '教务平台',
      showTabbar: true
    },
    component: () => import('@/views/edu/Index.vue')
  },
  {
    path: '/:appId/edu/clazz/:id',
    name: 'ClazzDetail',
    meta: {
      title: '班级详情'
    },
    component: () => import('@/views/edu/clazz/Index.vue')
  },
  {
    path: '/:appId/edu/student/:id/boundWxUser',
    name: 'StudentBoundWxUser',
    meta: {
      title: '学员绑定微信'
    },
    component: () => import('@/views/edu/student/BoundWxUser.vue')
  },
  {
    path: '/:appId/luckDraw',
    name: 'LuckDraw',
    meta: {
      title: '抽奖转盘'
    },
    component: () => import('@/views/activity/luckdraw/Index.vue')
  }

]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem('token')
//   if(to.path === '/cart') {
//     if(token) {
//       next()
//     } else {
//       Vue.prototype.$toast('请先登录')
//       setTimeout(() => {
//         next('/ucenter')
//       }, 1000)
//     }
//     return
//   }
//   next()
// })


export default router
