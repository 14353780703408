/**
 * 微信开方平台全局守卫
 */
import router from './router'
import request from '@/utils/request'
import store from './store'

/**
 * 取得授权方的appId，此appId是url的一部分，不是query参数
 * @returns
 */
const getAppId = () => {
  let pathname = window.location.pathname
  let arrs = pathname.split('/')
  let wxAppId = ''
  for(let p in arrs) {
    if(arrs[p].indexOf('wx') !== -1) {
      wxAppId = arrs[p]
    }
  }
  return wxAppId
}

/**
 * 清除多余参数
 */
const removeCode = (to) => {
  let path = to.path
  for (let i in to.query) {
    if (i !== 'code' && i !== 'state' && i !== 'appid') {
      path = path + '&' + i + '=' + to.query[i]
    }
  }
  // path = path === '' ? path : path.substring(1, path.length)
  // path = path === '' ? path : '/?' + path
  return path
}

/**
 * 白名单， 不进行全局守卫，由页面的路由处理
 */
let whiteList = ['/wxCom/auth', '/wxCom/authSucc', '/wxPay/callbackTicket' ]


router.beforeEach((to, from, next) => {
  console.log(process.env.NODE_ENV)
  if(process.env.NODE_ENV === 'development') {

    whiteList.push('/wx0d38d3bc1b0b3d56')
    whiteList.push('/myGroupOnTeamList/wx0d38d3bc1b0b3d56')
    // whiteList.push('/groupOnResult/wx0d38d3bc1b0b3d56/12/20210513000003')
    whiteList.push('/ucenter')
    whiteList.push('/guardian')
    whiteList.push('/edu')
    whiteList.push('/distribute')
    whiteList.push('/groupOn')
    whiteList.push('/freeTeam')
    whiteList.push('/luckDraw')

    // whiteList.push('/student/classHour/list')
    // whiteList.push('/student/classHourLog/list')

    // whiteList.push('/student/lessonLog/list')
    // whiteList.push('/student/lessonLog/detail')
    // whiteList.push('/student/signInOut/list')
  }
  if(to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '易办学'
  }
  if(inWhiteList(whiteList, to.path)) {
    // 白名单页面，直接跳转，由页面的路由处理
    next()
  } else {
    let fullPath = to.fullPath
    // 从url中获取授权方的appId
    let appId = getAppId()
    if (fullPath.includes('code') && fullPath.includes('appid')) {
      fullPath = removeCode(to)
    }
    let redirectUrl = encodeURIComponent(window.location.origin + fullPath)

    // 从“to”对象中code,state,appid等信息，如果有值，则说明这个to请求，是微信回调发起的
    let {code, state, appid} = to.query
    // 从session中取得token
    let token = sessionStorage.getItem('token')
    if (code && state && appid && !token) {
      // 微信服务器回调发起的请求，有code和appid，但是还没有token，调用后台，获取用户信息
      request.get('/api/wxcomjs/loadUserInfoByCode', {params: {code, appId}}).then(res => {
        if(res.succ) {
          sessionStorage.setItem('token', res.data.token)
          sessionStorage.setItem('userInfo', JSON.stringify(res.data))
          store.commit('setUserInfo', res.data)
          store.commit('setToken', res.data.token)
          // next({path: fullPath, replace: true })
          next()
        } else {
          // 重新登录
          reLogin(appId, redirectUrl)
        }
      })
    } else if (token) {
      // 判断token是否可用
      request.get('/api/wxcomjs/checkUserToken', {token}).then(res => {
        if(res.succ) {
          // token可用
          next()
        } else {
          sessionStorage.removeItem('token')
          // TODO store中清空token
          store.commit('removeUserInfo')
          // 重新登录
          reLogin(appId, redirectUrl)
        }
      })
    } else {
      reLogin(appId, redirectUrl)
    }
  }
})
router.afterEach((to) => {
  if(!sessionStorage.getItem('initLink')) {
    // 针对ios，记录首次访问应用时的页面链接，用于微信jssdk的url验证
    sessionStorage.setItem('initLink', document.URL)
  }

  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  // setTimeout(() => {
  //   // 此处粘贴百度统计复制过来的代码
  //   var _hmt = _hmt || [];
  //   (function() {
  //     var hm = document.createElement('script')
  //     hm.src = 'https://hm.baidu.com/hm.js?f9df1ded612a0bdb76da7eb231965459'
  //     var s = document.getElementsByTagName('script')[0]
  //     s.parentNode.insertBefore(hm, s)
  //   })()
  // }, 0)
})

const inWhiteList = (whiteList, path) => {
  for(let i = 0;i < whiteList.length; i ++) {
    if(path.indexOf(whiteList[i]) !== -1) {
      return true
    }
  }
  return false
}

const reLogin = (appId, redirectUrl, comAppId) => {
  // 重新登录
  comAppId = process.env.VUE_APP_COM_APPID
  // 发起网页授权
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&component_appid=${comAppId}#wechat_redirect`
}
