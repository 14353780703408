<template>
  <div class="home">

    <h1>{{ userInfo.nickname }}
      <img :src="userInfo.avatar" style="width: 50px; border-radius: 50%" />
    </h1>
    <van-button type="info" @click="clearToken">清理Token</van-button>
    <router-view />
  </div>
</template>
<script>
export default {
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  methods: {
    clearToken() {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userInfo')
      sessionStorage.removeItem('initLink')
      this.$store.commit('removeUserInfo')
    }
  }
}
</script>

