<template>
  <div id="app">
    <router-view />
    <MyTabbar v-if="$route.meta.showTabbar" ref="myTabBar" />
  </div>
</template>
<script>
import MyTabbar from './views/components/MyTabbar.vue'
export default {
  components: {
    MyTabbar
  },
  mounted() {
    this.loadStateFromSession()
    window.addEventListener('unload', () => {
      // 将vuex中的userInfo保存到sessionStorage中
      sessionStorage.setItem('userInfo', JSON.stringify(this.$store.state.userInfo))
    })
    window.addEventListener('beforeunload', () => {
      // 页面刷新前，清空initLink，与IOS有关
      // alert('刷新页面')
      sessionStorage.removeItem('initLink')
    })
  },
  methods: {
    loadStateFromSession() {
      this.$store.dispatch('loadUseInfo', JSON.parse(sessionStorage.getItem('userInfo')))
      this.$store.dispatch('loadToken', sessionStorage.getItem('token'))
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100%;
}
html,
body {
  height: 100%;
  background-color: #efefef;
  font-size: 14px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
